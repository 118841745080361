@import '../../../variables.scss';
$archived-opacity: 0.5;

.task-page {
  display: flex;
  flex-direction: column;
  flex: 1;

  .task-container {
    .table-wrapper {
      .table-content {
        .col-status {
          position: relative;
          .status-archived {
            color: $font-red-color;
            position: absolute;
            right: 0;
            top: 0;
          }
          .task-status {
            color: $defautl-font-color;
            &.doing {
              color: #ffa000;
            }
            &.completed {
              color: $app-primary-color;
            }
          }
        }

        .row-archived {
          color: rgba(209, 209, 215, $archived-opacity);
          .ant-table-selection-column {
            .ant-checkbox-inner {
              border: 1px solid rgba(209, 209, 215, $archived-opacity);
            }
          }
          .col-status {
            .task-status {
              color: rgba(209, 209, 215, $archived-opacity);
              &.doing {
                color: rgba(255, 160, 0, $archived-opacity);
              }
              &.completed {
                color: rgba(23, 185, 120, $archived-opacity);
              }
            }
          }
        }
      }
    }
  }
}

.ant-table-filter-dropdown {
  .custom-filter {
    .filter-options {
      width: 100%;
      padding: 4px 0;
      max-height: 264px;
      .filter-item {
        display: block;
        padding: 5px 12px;
      }
    }
    .filter-dropdown-btns {
      text-align: center;
      border-top: 1px solid #f0f0f0;
      padding: 5px 3px;
    }
  }
}
