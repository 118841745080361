/* Variables */
$header-height: 40px;
$left-menu-width: 200px;
$left-menu-collapsed-width: 83px;
$border-box-radius: 10px;

/* app colors */
$defautl-font-color: #d1d1d7;
$app-bg-color: #151515;
$app-primary-color: #17b978;
$app-bg-box: #26292e;
$app-bg-page-content: #1e2025;
$app-box-border-color: #53555a;

$modal-bg-color: #242e4c;
$font-red-color: #f44336;
